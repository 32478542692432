import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BASE_API_URL } from "../base-api.url";
import {Observable} from "rxjs";
import { ReferentielProduitReferentielBaseResource } from "../../models/referentiel";
import { ReferentielProduitResource } from "../../models/referentiel";
import { ParametrageProduitIWAYPResource } from "@core/backend/models/referentiel/ParametrageProduitIWAYPResource";
import {
  ParametrageProduitINEOTEMPO1EURResource
} from "@core/backend/models/referentiel/ParametrageProduitINEOTEMPO1EURResource";

@Injectable({
  providedIn: "root"
})
export class ReferentielProduitService {

  constructor(private httpClient: HttpClient, @Inject(BASE_API_URL) private baseUrl: string) {

  }

  public getReferentielProduits$<TReferentiel extends ReferentielProduitReferentielBaseResource>(produit?: string): Observable<Array<ReferentielProduitResource<TReferentiel>>> {
    const url = produit ? `${this.baseUrl}referentiel-produits?produit=${produit}` : `${this.baseUrl}referentiel-produits`;

    return this.httpClient.get<Array<ReferentielProduitResource<TReferentiel>>>(url, {
      responseType: "json",
      headers: {"Accept": "application/json"}
    });
  }

  public getReferentielIWAYP$(): Observable<ParametrageProduitIWAYPResource> {
    return  this.httpClient.get<ParametrageProduitIWAYPResource>(`${this.baseUrl}admin/produit/IWAYP`, {
      responseType: "json",
      headers: { "Accept": "application/json" }
    });
  }

  public postReferentielIWAYP$(commande: ParametrageProduitIWAYPResource): Observable<never> {
    return  this.httpClient.post<never>(`${this.baseUrl}admin/produit/IWAYP`, commande, {
      responseType: "json",
      headers: { "Accept": "application/json" }
    });
  }

  public getReferentielINEOTEMPO1EUR$(): Observable<ParametrageProduitINEOTEMPO1EURResource> {
    return  this.httpClient.get<ParametrageProduitINEOTEMPO1EURResource>(`${this.baseUrl}admin/produit/INEOTEMPO1EUR`, {
      responseType: "json",
      headers: { "Accept": "application/json" }
    });
  }

  public postReferentielINEOTEMPO1EUR$(commande: ParametrageProduitINEOTEMPO1EURResource): Observable<never> {
    return  this.httpClient.post<never>(`${this.baseUrl}admin/produit/INEOTEMPO1EUR`, commande, {
      responseType: "json",
      headers: { "Accept": "application/json" }
    });
  }
}




